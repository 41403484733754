import { useEffect, useState } from 'react'

export default function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value)

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

		return () => {
			clearTimeout(timer)
		}
	}, [value, delay])

	// Return undefined to check consistently on the value in the parent component
	if (debouncedValue === '') {
		return undefined
	}

	return debouncedValue
}
