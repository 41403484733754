/* eslint-disable react/require-default-props */
import React from 'react'
import { Button, Icon } from '@damen/ui'
import ResponseMessage from '@components/UI/ResponseMessage'

interface Props {
	title: string
	content: string
	buttonLabel?: string
	buttonOnClick?: (event: React.MouseEvent) => void
}

const GenericError = ({
	title,
	content,
	buttonLabel,
	buttonOnClick
}: Props) => {
	return (
		<ResponseMessage title={title} content={content}>
			{buttonLabel && (
				<Button.Default
					text={buttonLabel}
					icon={Icon.LeftChevron}
					onClick={buttonOnClick}
				/>
			)}
		</ResponseMessage>
	)
}

export default GenericError
