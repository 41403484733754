import React from 'react'
import Spacer from '@components/Spacer'
import { Wrapper, LeftContainer, RightContainer } from './styles'
import { TwoColRowProps } from './types'

const TwoColRow = ({
	left,
	right,
	spacerBorderBottom,
	paddingTop,
	paddingBottom,
	mobileLayout = 'row',
	gap,
	...rest
}: TwoColRowProps) => (
	<Spacer
		borderBottom={spacerBorderBottom}
		paddingTop={paddingTop}
		paddingBottom={paddingBottom}
		{...rest}
	>
		<Wrapper mobileLayout={mobileLayout} gap={gap}>
			<LeftContainer mobileLayout={mobileLayout}>{left}</LeftContainer>
			<RightContainer mobileLayout={mobileLayout}>
				{right && right}
			</RightContainer>
		</Wrapper>
	</Spacer>
)

export default TwoColRow
