import styled from 'styled-components'

import { theme } from '@damen/ui'

const StickyFilters = styled.div`
	position: sticky;
	top: 0;
	z-index: 3;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: ${theme.colors.white};
	}
`

export default StickyFilters
