/* eslint-disable react/require-default-props */
import React from 'react'
import { Icon } from '@damen/ui'
import { colors } from '@damen/ui/lib/cjs/styles'
import InfiniteScroller from '@components/InfiniteScroller'
import { LoadingText, SpinnerWrapper } from './styles'

interface Props {
	isLoading?: boolean
	loadingText?: string
	loadNext: () => Promise<void> | void
}

const LoadMoreLoader = ({ isLoading, loadingText, loadNext }: Props) => {
	if (isLoading) {
		return (
			<>
				<SpinnerWrapper>
					<Icon.Spinner fill={colors.blue} />
				</SpinnerWrapper>
				<LoadingText>{loadingText}</LoadingText>
			</>
		)
	}

	return <InfiniteScroller loadNext={loadNext} />
}

export default LoadMoreLoader
