import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import { FiltersGroupWrapper, Filter } from './styles'

interface FilterProps {
	amount: number
}

export const FilterSkeleton = ({ amount }: FilterProps) => {
	const filters = amount
	const filetrId = []
	for (let i = 0; i < amount; i += 1) {
		filetrId.push(i)
	}

	return (
		<FiltersGroupWrapper>
			{[...Array(filters)].map((item, i) => {
				return (
					<Filter key={filetrId[i]} amount={filters}>
						<SkeletonLoader height={57} />
					</Filter>
				)
			})}
		</FiltersGroupWrapper>
	)
}

export default FilterSkeleton
