import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import { Container, Indicator, Buttons } from './styles'

const PaginationSkeleton = () => {
	const pages = 3
	const pageId = [0, 1, 2]

	return (
		<Container>
			{[...Array(pages)].map((item, i) => {
				return (
					<Indicator key={pageId[i]}>
						<SkeletonLoader circle height={32} width={32} />
					</Indicator>
				)
			})}
			<Buttons>
				<SkeletonLoader circle height={32} width={32} />
				<SkeletonLoader circle height={32} width={32} />
			</Buttons>
		</Container>
	)
}

export default PaginationSkeleton
