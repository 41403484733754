import {
	Container,
	FilterSearchClearButton,
	FilterSearchWrapper,
	IconContainer,
	Options,
	Option,
	SearchIconWrapper,
	SearchInput,
	NoResultsFound
} from '@components/UI/Filter/Search/styles'
import { Form, Icon, theme } from '@damen/ui'
import React, {
	useEffect,
	useRef,
	useState,
	KeyboardEvent,
	ReactNode
} from 'react'
import styled from 'styled-components'

const Button = styled.button`
	min-width: 100%;
	display: flex;
	align-items: center;
	background-color: transparent;
	cursor: pointer;
	border: 0;
	padding: 16px 15px 15px 24px;
	line-height: ${theme.typography.lineHeightTextSmall};
	letter-spacing: 0.2px;
	font-size: ${theme.typography.fontSizeTextSmall}px;
	outline: none;
	color: ${theme.colors.marineBlack};
	position: relative;
	justify-content: space-between;
	font-weight: 700;
	transition: background-color ${theme.timing.default} ease-in-out;
	white-space: pre;

	> div {
		margin-left: 0 !important;
	}

	&:focus,
	&:hover {
		background-color: ${theme.colors.greyAccentLight};
	}

	&:disabled {
		background-color: ${theme.colors.greyAccent};
		color: ${theme.colors.greyAccentDark};
		pointer-events: none;
	}

	@media ${theme.legacyMediaQueries.xs} {
		margin: 0;
	}
`

interface Props {
	label: ReactNode
	options: Array<{
		value: string
		label: string
		selected?: boolean
	}>
	onChange: (selectedValues: string[]) => void
	disabled?: boolean
	search?: boolean
	searchPlaceholder?: string
	noResultsFound?: string
}

const Select = ({
	label,
	options,
	onChange,
	disabled,
	search,
	searchPlaceholder,
	noResultsFound
}: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [searchValue, setSearchValue] = useState<string>('')

	const containerRef = useRef<HTMLDivElement>(null)
	const { current: currentContainerRef } = containerRef
	useEffect(() => {
		const elem = currentContainerRef
		if (!elem) {
			return
		}
		const handleClickOutside = (event: any) => {
			if (!elem.contains(event.target)) {
				setIsOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		// eslint-disable-next-line consistent-return
		return () =>
			document.removeEventListener('mousedown', handleClickOutside)
	}, [currentContainerRef])

	// Accessibility
	const optionsRefs: HTMLDivElement[] = []
	const handleButtonSelectorKeyCapture = (event: KeyboardEvent<Element>) => {
		if (event.key === 'ArrowDown') {
			optionsRefs[0].focus()
		}
	}
	const handleOptionKeyCapture = (
		event: KeyboardEvent<Element>,
		index: number
	) => {
		if (disabled) {
			return null
		}

		if (event.shiftKey && event.key === 'Tab') {
			return null
		}

		if (index === options.length - 1 && event.key === 'Tab') {
			return event.preventDefault()
		}

		if (event.key && event.key === 'Escape' && setIsOpen) {
			return setIsOpen(false)
		}

		if (event.key === 'ArrowUp') {
			event.preventDefault()
			if (index !== 0) {
				optionsRefs[index - 1].focus()
			}
		}

		if (event.key === 'ArrowDown') {
			event.preventDefault()

			if (index !== options.length - 1) {
				optionsRefs[index + 1].focus()
			}
		}

		return null
	}

	return (
		<Container isOpen={isOpen} ref={containerRef}>
			<Button
				disabled={disabled}
				data-test="toggleOpenClose"
				onClick={() => setIsOpen(!isOpen)}
				onKeyDown={handleButtonSelectorKeyCapture}
			>
				{label}
				<IconContainer isRotated={isOpen}>
					<Icon.CaretDown
						fill={
							disabled
								? theme.colors.greyAccentDark
								: theme.colors.marineBlack
						}
						height={10}
						width={10}
					/>
				</IconContainer>
			</Button>
			<Options
				className="options-list"
				isOpen={isOpen}
				data-test="optionsList"
				data-test-toggle={isOpen}
			>
				{search && (
					<FilterSearchWrapper>
						<SearchInput
							onChange={(e) => setSearchValue(e.target.value)}
							type="search"
							value={searchValue}
							placeholder={searchPlaceholder ?? 'Search option'}
						/>

						<SearchIconWrapper>
							{searchValue ? (
								<FilterSearchClearButton
									onClick={() => setSearchValue('')}
								>
									<Icon.CloseThin
										fill={theme.colors.marineBlack}
										width={12}
										height={12}
									/>
								</FilterSearchClearButton>
							) : (
								<Icon.Search
									fill={theme.colors.marineBlack}
									width={15}
									height={15}
								/>
							)}
						</SearchIconWrapper>
					</FilterSearchWrapper>
				)}

				{options
					.filter((option) =>
						option.label
							.toLowerCase()
							.includes(searchValue.toLowerCase())
					)
					.map((option, index) => (
						<Option key={option.value}>
							<Form.Checkbox
								background="blue"
								checked={option.selected || false}
								inputRef={(elem) => {
									if (elem) {
										optionsRefs.push(elem)
									}
								}}
								label={option.label}
								name={option.value}
								value={option.value}
								onChange={() => {
									const selectedValues = options
										.filter((item) => item.selected)
										.map((item) => item.value)

									onChange(
										selectedValues.includes(option.value)
											? selectedValues.filter(
													(value) =>
														value !== option.value
											  )
											: [...selectedValues, option.value]
									)
								}}
								onKeyDown={(
									event: React.KeyboardEvent<Element>
								) => handleOptionKeyCapture(event, index)}
							/>
						</Option>
					))}
				{searchValue !== '' &&
					options.filter((option) =>
						option.label
							.toLowerCase()
							.includes(searchValue.toLowerCase())
					).length <= 0 && (
						<NoResultsFound>
							{noResultsFound
								? noResultsFound.replace('[VALUE]', searchValue)
								: `[There are no filters that match ‘${searchValue}’]`}
						</NoResultsFound>
					)}
			</Options>
		</Container>
	)
}

export default Select
