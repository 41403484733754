import { theme } from '@damen/ui'
import styled, { keyframes } from 'styled-components'

const spinnerRotation = keyframes`
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
`

export const SpinnerWrapper = styled.div`
	svg {
		animation: ${spinnerRotation} ${theme.timing.slow} infinite;
	}
`

export const LoadingText = styled.span`
	display: block;
	margin-top: ${theme.layout.spacingM};
	color: ${theme.colors.grey};
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeTextSmall}px;
`
