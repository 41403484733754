import React from 'react'
import { SpacerProps } from './types'
import { ComponentWrapper } from './styles'

const Spacer = ({
	children,
	className,
	borderBottom,
	paddingTop,
	paddingBottom
}: SpacerProps) => (
	<ComponentWrapper
		borderBottom={borderBottom}
		className={className}
		paddingTop={paddingTop ?? true}
		paddingBottom={paddingBottom ?? true}
	>
		{children}
	</ComponentWrapper>
)

export default Spacer
