import styled from 'styled-components'
import { Button, theme } from '@damen/ui'

const MAX_WIDTH_SELECTED_ITEM = 200

export const FilterSelectedItems = styled.div`
	display: flex;
	flex-direction: row;
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeTextSmall}px;
	margin-top: 40px;

	@media ${theme.legacyMediaQueries.md} {
		margin-top: 0;
	}
`

export const FilterSelectedItemsContainer = styled.div`
	@media ${theme.legacyMediaQueries.md} {
		display: flex;
		flex-flow: row wrap;
		margin-bottom: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}
`

export const Item = styled.div`
	width: auto;
	max-width: ${MAX_WIDTH_SELECTED_ITEM}px;
	height: 48px;
	box-sizing: border-box;
	margin: 6px 24px 6px 0;
	padding: 12px 58px 12px 30px;
	border-radius: 48px;
	background-color: ${theme.colors.greyAccentLight};
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
`

export const ItemText = styled.span`
	max-width: ${MAX_WIDTH_SELECTED_ITEM - 18}px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: ${theme.typography.fontSizeTextSmall}px;
	color: ${theme.colors.marineBlack};
`

export const ClearButton = styled.button`
	cursor: pointer;
	position: absolute;
	top: 9px;
	right: 15px;
	border: 3px solid transparent;
	margin: 0;
	background-color: transparent;
`
export const ClearAllButton = styled.button`
	width: auto;
	height: 48px;
	line-height: 48px;
	margin: 0 0 0 2px;
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeTextSmall}px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.44px;
	color: ${theme.colors.blue};
	background: none;
	border: 0;
	white-space: nowrap;
	padding: 0 1rem;
`
export const ClearAllNextButton = styled(Button.Default)`
	width: auto;
	height: 48px;
	line-height: 48px;
	margin: 6px 24px 6px 0 !important;
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeTextSmall}px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.44px;
	color: ${theme.colors.blue};
	background: none;
	border: 0;
	white-space: nowrap;
	padding: 0 1rem;
`
export const ClearAllButtonText = styled.span`
	display: inline-block;
	margin-left: 16px;
`

export const SelectedItemsLabel = styled.span`
	height: 48px;
	line-height: 48px;
	display: inline-block;
	margin-right: 24px;
	font-weight: 500;
	position: relative;

	@media ${theme.legacyMediaQueries.md} {
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: 6px;
	}
`
