import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
`

export const Indicator = styled.span`
	padding: 0;
`

export const Buttons = styled.span`
	display: flex;
	margin-left: 32px;

	button {
		:first-child {
			margin-right: 8px;
		}
	}
`
