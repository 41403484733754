import { theme } from '@damen/ui'
import styled from 'styled-components'

export const FiltersGroupWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;

	@media ${theme.legacyMediaQueries.md} {
		display: flex;
		flex-flow: row wrap;
		gap: 0;
	}
`

export const GroupContainer = styled.div`
	width: 100%;
`
