import React from 'react'

import ActiveFilters from '@components/ActiveFilters'
import { useTranslationContext } from '@contexts/TranslationContext'

interface Props<T extends Record<string, any>> {
	facets: Array<{
		key: keyof T & string
		label: string
		search?: boolean
		options: Array<{
			id: string
			name: string
		}>
	}>
	activeFilters: T
	onChangeFilters: (filters: T) => void
}

const ActiveFacets = <T extends Record<string, any>>({
	facets,
	activeFilters,
	onChangeFilters
}: Props<T>) => {
	const { translations } = useTranslationContext()
	const notNullFacets = facets.filter(({ options }) => options !== null)

	return (
		<ActiveFilters
			label={translations?.filterTitle ?? '[Filters:]'}
			clearAll={() => onChangeFilters({} as T)}
			clearAllLabel={translations?.filterClearAll ?? '[Clear all]'}
			activeFilters={[
				...notNullFacets.flatMap(({ key, options }) =>
					options
						.filter(({ id }) => activeFilters[key]?.includes(id))
						.map(({ id, name }) => ({
							key: id,
							label: name,
							remove: () => {
								onChangeFilters({
									...activeFilters,
									[key]: activeFilters[key].filter(
										(item) => item !== id
									)
								})
							}
						}))
				)
			]}
		/>
	)
}

export default ActiveFacets
