import styled, { css } from 'styled-components'
import { theme } from '@damen/ui'
import { LayoutProps } from './types'

export const Wrapper = styled.div<LayoutProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${({ gap }) => gap || '0'}px;

	${({ mobileLayout }) =>
		css`
			flex-direction: ${(mobileLayout === 'column' && 'column') ||
			(mobileLayout === 'columnReverse' && 'column-reverse') ||
			(mobileLayout === 'row' && 'row')};

			align-items: ${(mobileLayout === 'column' && 'flex-start') ||
			(mobileLayout === 'columnReverse' && 'flex-start') ||
			(mobileLayout === 'row' && 'center')};
		`}

	@media ${theme.legacyMediaQueries.sm} {
		flex-direction: row;
	}
`

export const RightContainer = styled.div<LayoutProps>`
	width: ${({ mobileLayout }) => (mobileLayout !== 'row' ? '100%' : 'auto')};
	margin-top: 0;
	margin-left: ${({ mobileLayout }) =>
		mobileLayout === 'row' ? '20px' : '0'};
	margin-bottom: ${({ mobileLayout }) =>
		mobileLayout === 'columnReverse' ? '20px' : '0'};
	padding-bottom: ${({ mobileLayout }) =>
		mobileLayout === 'columnReverse' ? '24px' : '0'};
	border-bottom: ${({ mobileLayout }) =>
		mobileLayout === 'columnReverse'
			? `1px solid ${theme.colors.greyAccentLight}`
			: 'none'};

	@media ${theme.legacyMediaQueries.sm} {
		width: 50%;
		text-align: right;
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: none;
		margin: 0 0 auto;
	}
`

export const LeftContainer = styled.div<LayoutProps>`
	width: auto;
	margin-top: 0;

	@media ${theme.legacyMediaQueries.sm} {
		width: 50%;
	}
`
