import React from 'react'
import { useInView } from 'react-intersection-observer'

import { InfiniteScrollerProps } from './types'
import { Container } from './styles'

const InfiniteScroller = ({
	loadNext,
	preloadDistance = 0
}: InfiniteScrollerProps) => {
	const [containerRef] = useInView({
		rootMargin: `-${preloadDistance}px 0px`,
		onChange: async (inView) => {
			if (!inView) {
				return
			}

			await loadNext()
		},
		triggerOnce: true
	})

	return <Container ref={containerRef} />
}

export default InfiniteScroller
