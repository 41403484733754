import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import {
	TableBodyElement,
	TableColumnElement,
	TableContentWrapper,
	TableElement,
	TableHeadElement,
	TableHeading,
	TableHeadRow,
	TableRow
} from './styles'

const TableSkeleton = ({ rows = 10 }) => {
	const columns = 5
	const tableColumnId = [0, 1, 2, 3, 4]
	const tableRowId = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

	return (
		<TableElement>
			<TableHeadElement>
				<TableHeadRow>
					{[...Array(columns)].map((item, i) => (
						<TableHeading key={tableColumnId[i]}>
							<SkeletonLoader />
						</TableHeading>
					))}
				</TableHeadRow>
			</TableHeadElement>

			<TableBodyElement>
				{[...Array(rows)].map((itemRow, i) => (
					<TableRow key={tableRowId[i]}>
						{[...Array(columns)].map((itemColumnn, index) => {
							return (
								<TableColumnElement key={tableColumnId[index]}>
									<TableContentWrapper>
										<SkeletonLoader />
									</TableContentWrapper>
								</TableColumnElement>
							)
						})}
					</TableRow>
				))}
			</TableBodyElement>
		</TableElement>
	)
}

export default TableSkeleton
