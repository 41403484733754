import {
	ClearButton,
	Item,
	ItemText
} from '@components/UI/Filter/Selected/styles'
import { Icon } from '@damen/ui'
import React from 'react'

interface Props {
	label: string
	onRemove: () => void
}

const ActiveFilterItem = ({ label, onRemove }: Props) => (
	<Item data-test="selectedOption">
		<ItemText>{label}</ItemText>
		<ClearButton onClick={onRemove}>
			<Icon.Close width={12} height={12} />
		</ClearButton>
	</Item>
)

export default ActiveFilterItem
