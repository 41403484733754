/* eslint-disable @typescript-eslint/no-shadow */
import {
	ClearAllNextButton,
	FilterSelectedItems,
	FilterSelectedItemsContainer,
	SelectedItemsLabel
} from '@components/UI/Filter/Selected/styles'
import { Icon } from '@damen/ui'
import React from 'react'
import ActiveFilterItem from './ActiveFilterItem'

interface Props {
	label: string
	clearAll: () => void
	clearAllLabel: string
	activeFilters: Array<{
		key: string
		label: string
		remove: () => void
	}>
}

const ActiveFilters = ({
	label,
	clearAll,
	clearAllLabel,
	activeFilters
}: Props) => {
	if (activeFilters.length === 0) {
		return null
	}

	return (
		<FilterSelectedItems>
			<SelectedItemsLabel>{label}</SelectedItemsLabel>
			<FilterSelectedItemsContainer>
				{activeFilters.map(({ key, label, remove }) => (
					<ActiveFilterItem
						key={key}
						label={label}
						onRemove={remove}
					/>
				))}
				<ClearAllNextButton
					colorType="blue"
					icon={Icon.CloseThin}
					iconSize={12}
					iconPosition="left"
					text={clearAllLabel}
					onClick={clearAll}
					type="button"
					buttonType="text"
					isSmall
				/>
			</FilterSelectedItemsContainer>
		</FilterSelectedItems>
	)
}
ActiveFilters.whyDidYouRender = true
export default ActiveFilters
