import React from 'react'
import Container from '@components/Container/Container'
import Spacer from '@components/Spacer'
import TwoColRow from '@components/TwoColRow'
import SearchSkeleton from '@skeletons/Search'
import TableSkeleton from '@skeletons/Table'
import PaginationSkeleton from '@skeletons/Pagination'
import FilterSkeleton from '@skeletons/Filter'
import { PaginationWrapper } from '@components/GenericOverview/styles'
import { GenericOverviewSkeletonProps } from './types'

const GenericOverviewSkeleton: React.FC<GenericOverviewSkeletonProps> = ({
	filterAmount = 5
}) => (
	<Container>
		<TwoColRow
			mobileLayout="columnReverse"
			spacerBorderBottom={false}
			paddingBottom={false}
			left={<SearchSkeleton />}
			right={undefined}
		/>
		<Spacer paddingBottom={false}>
			<FilterSkeleton amount={filterAmount} />
		</Spacer>
		<Spacer paddingTop={false}>
			<TableSkeleton />
			<PaginationWrapper>
				<PaginationSkeleton />
			</PaginationWrapper>
		</Spacer>
	</Container>
)

export default GenericOverviewSkeleton
