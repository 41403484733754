import React from 'react'
import { useRouter } from 'next/router'
import GenericError from '.'
import { EmptyTranslations } from './types'

type Props = {
	type: 'noresult' | 'error'
	searchQuery: string
	translations: EmptyTranslations
}

export const DefaultGenericError = ({
	type,
	searchQuery,
	translations
}: Props): JSX.Element => {
	const router = useRouter()

	if (type === 'error') {
		return (
			<GenericError
				title={translations.errorTitle}
				content={translations.errorContent}
				buttonLabel={translations.errorButtonContent}
				buttonOnClick={router.reload}
			/>
		)
	}

	if (type === 'noresult') {
		const isSearchRequest = searchQuery?.length > 0

		const emptyTitle = isSearchRequest
			? translations.searchNotFoundTitle?.replace('[VALUE]', searchQuery)
			: translations.emptyTitle

		const emptyContent = isSearchRequest
			? translations.searchNotFoundContent
			: translations.emptyContent

		return <GenericError title={emptyTitle} content={emptyContent} />
	}
	return null
}
